import Image from "next/image";
import Link from "next/link";

import { VectorFilled } from "@deeplang/icons";

import { ossUrl } from "@/utils";

export interface LogoProps {
  width?: number;
  height?: number;
  collapse?: boolean;
  isBlue?: boolean;
}

function Logo({
  collapse = true,
  isBlue = false,
  width = 40,
  height = 40,
}: {
  width?: number;
  height?: number;
  collapse?: boolean;
  isBlue?: boolean;
}) {
  const logoUrl = `${ossUrl}/lingoreader/pic/lingo_logo.svg`;
  const textLogoUrl = `${ossUrl}/lingoreader/pic/lingo-log-text.svg`;
  return (
    <Link
      href="/?dashboard=true"
      className="justify-center items-center gap-1 inline-flex"
    >
      <Image
        alt="logo"
        className="block max-w-full"
        src={logoUrl}
        width={width}
        height={height}
      />
      {/* {!collapse && <VectorFilled width={34} height={16} />} */}
      {!collapse && <Image alt="logo" src={textLogoUrl} width={34} height={16} />}
    </Link>
  );
}

export default Logo;
