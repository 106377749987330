"use client";

import { useEffect } from "react";
import Link from "next/link";
import Logo from "@/components/logo";
import { usePassport } from "@/packages/passport";
import { PuzzlePieceLined } from "@deeplang/icons";
import { useRouter, useSearchParams } from "next/navigation";

const HeaderItem = ({ title }: { title: string }) => (
  <div className="justify-start items-center gap-1.5 flex">
    <PuzzlePieceLined className="text-sm" />
    <div className="text-center text-zinc-800 text-base leading-normal tracking-wide">
      {title}
    </div>
  </div>
);

const LandingHeader = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const dashboard = searchParams.get("dashboard");
  const {
    state: { logged },
  } = usePassport();

  useEffect(() => {
    if (logged && dashboard !== "true") {
      router.push("/home");
    }
  }, [logged, dashboard, router]);

  return (
    <header className="w-full border border-b fixed top-0 left-0 z-100">
      <div className="h-14 relative bg-white m-auto px-6 md:px-12">
        <div className="w-full h-14 bg-white items-center flex">
          <div className="w-full self-stretch justify-between items-center gap-36 inline-flex">
            <div className="relative flex items-center">
              <Logo collapse={false} width={38} height={38} isBlue />
            </div>
            <div className="grow shrink basis-0 h-9 justify-end items-center gap-12 flex">
              <div className="flex justify-start items-center gap-12">
                <Link href="/home">
                  <div className="px-3 py-1.5 bg-gradient-to-b from-zinc-700 to-zinc-800 hover:from-stone-500 hover:to-zinc-800 rounded-lg shadow border border-neutral-500 justify-center items-center gap-1.5 inline-flex">
                    <div className="text-center text-white text-sm leading-normal tracking-wide">
                      进入灵狗
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default LandingHeader;
